$(document).ready(function() {
    $(".nav-tabs a").click(function() {
        $(this).tab('show');
    });

    $(".nav-tabs-sub1 a").click(function() {
        $(this).tab('show');
    });

    $(".nav-tabs-sub2 a").click(function() {
        $(this).tab('show');
    });

    $(".nav-tabs-sub3 a").click(function() {
        $(this).tab('show');
    });

    var a = [];
    var j = 0;
    $('.nav-tabs-sub1 li').each(function(e, i) {
        let x = $(this).hasClass('active show');
        a[j] = x;
        j++;
    });
    // console.log(a);
    if (jQuery.inArray(true, a) != -1) {} else {
        $('.nav-tabs-sub1 li:first-child').find('a').click();
    }
    var b = [];
    var k = 0;
    $('.nav-tabs-sub2 li').each(function(e, i) {
        let y = $(this).hasClass('active show');
        b[k] = y;
        k++;
    });
    // console.log(b);
    if (jQuery.inArray(true, b) != -1) {} else {
        $('.nav-tabs-sub2 li:first-child').find('a').click();
    }
    var c = [];
    var l = 0;
    $('.nav-tabs-sub3 li').each(function(e, i) {
        let z = $(this).hasClass('active show');
        c[l] = z;
        l++;
    });
    // console.log(c);
    if (jQuery.inArray(true, c) != -1) {} else {
        $('.nav-tabs-sub3 li:first-child').find('a').click();
    }
    
    $(".left").click(function() {
        $("#myCarousel").carousel("prev");
    });
    $(".right").click(function() {
        $("#myCarousel").carousel("next");
    });
});

function loadWYSIWUGEditor(id) {
    $(`.edit_post, .post_description_${id}`).trumbowyg({
        btnsDef: {
            // Create a new dropdown
            image: {
                dropdown: ['insertImage', 'base64'],
                ico: 'insertImage'
            }
        },
        btns: [
            ['viewHTML'],
            ['undo', 'redo'], // Only supported in Blink browsers
            ['formatting'],
            ['strong', 'em', 'del'],
            ['superscript', 'subscript'],
            ['table'],
            ['link'],
            ['image'],
            ['insertAudio'],
            ['noembed'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['horizontalRule'],
            ['emoji'],
            ['specialChars'],
            ['removeformat'],
            ['foreColor'],
            ['backColor'],
            ['fontfamily'],
            ['fontsize'],
['lineheight'],
            ['textdirection'],
            ['fullscreen'],
        ],
        plugins: {
            resizimg: {
                minSize: 64,
                step: 16,
            },
        }
    });
}
$(document).ready(function() {




    gv_table = $('#gridview_table_').DataTable({
        paging: false,
        retrieve: true,
        "drawCallback": function(settings) {
            var course_img = $('.courses_wrap').width();
            $('.courses_wrap').find('img').css('height', course_img + 'px');
            $("#gridview_table_ thead").remove();
        },
        "order": [],
        "dom": 'tip',
        columnDefs: [{
            width: 150,
            type: 'non-empty-string',
            targets: 0
        }, ],
        fixedColumns: true
    });
    gv_table.order([0, "asc"]).draw();
});
$('.search_gridview').keyup(function() {
    $('#gridview_table_').DataTable().search($(this).val()).draw();
    $('#myCourse').DataTable().search($(this).val()).draw();
})

$('.gridview_table_length').change(function() {
    var pl = $(this).val();
    window.location.href = '/p=' + pl
})
$(document).ready(function() {
    $('#hidden_myCourse').DataTable({
        paging: false,
        "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
            var index = iDisplayIndexFull + 1;
            $("td:first", nRow).find('.sr-no').html(index);
            return nRow;
        },
    });
    
    table = $('#myCourse').removeAttr('width').DataTable({
        "pageLength": 60,
        paging: false,
		"info": false,
        "columnDefs": [{
                type: 'non-empty-string',
                targets: [3]
            } // define 'name' column as non-empty-string type
        ],
        "dom": "<'row'r><'table-responsive custom_top_scroll'<'top_custom_scroll'>'><'table-responsive custom_scroll't><'box-footer clearfix __p'<'pull-left page-info mar-top-17'i>p>",
        "drawCallback": function(settings) {

        },
        "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
            var index = iDisplayIndexFull + 1;
            $("td:first", nRow).find('.sr-no').html(index);
            return nRow;
        },
        "order": [],

    });

    $('#myCourse tr th').eq(3).css('width', '10px');
    $('#myCourse tr td').eq(3).css('width', '10px');
    $(".custom_scroll").scroll(function() {
        $(".custom_top_scroll")
            .scrollLeft($(".custom_scroll").scrollLeft());
    });
    $(".custom_top_scroll").scroll(function() {
        $(".custom_scroll")
            .scrollLeft($(".custom_top_scroll").scrollLeft());
    });
});

$(document).on('keyup', '.search_listview2', function() {
    $('#myCourse').DataTable().search($(this).val()).draw();
})

$(document).on('change', '.listview_table_length', function() {
    var pl = $(this).val();
    $('#myCourse').DataTable().page.len(pl).draw();
})

$(document).on('click', '.viewSwitchbtnRecords', function() {
    $('.viewSwitchbtnRecords').removeClass('active');
    var active = $(this).attr('data-id');
    $('.' + active + 'btn').addClass('active');
	
	$('.data-content').toggleClass('hidden');

    $('#myQa_paginate').display();
    $('#myQa_info').display();

})	

$(document).on('click', '.viewSwitchbtnQuestions', function() {
    $('.viewSwitchbtnQuestions').removeClass('active');
    var active = $(this).attr('data-id');
    $('.' + active + 'btn').addClass('active');
	
	$('.data-content').toggleClass('hidden');

})	

$(document).on('click', '.viewSwitchbtnForums', function() {
    $('.viewSwitchbtnForums').removeClass('active');
    var active = $(this).attr('data-id');
    $('.' + active + 'btn').addClass('active');
	
	$('.data-content').toggleClass('hidden');

})	

$(document).on('click', '.viewSwitchbtnCertificat', function() {
    $('.viewSwitchbtnCertificat').removeClass('active');
    var active = $(this).attr('data-id');
    $('.' + active + 'btn').addClass('active');
	
	$('.data-content').toggleClass('hidden');

})	

$(document).on('click', '.viewSwitchbtn', function() {
    $('.viewSwitchbtn').removeClass('active');
    var active = $(this).attr('data-id');
    console.log(active);
    $('.' + active + 'btn').addClass('active');  
    $('.member_table_view').hide();
    $('.' + active).show();
    var course_img = $('.courses_wrap').width();
    $('.courses_wrap').find('img').css('height', course_img + 'px');
    if (active == "course_tbl_listview") {
        
        $('#from_hidden_list').hide();
        $('.top_custom_scroll').height('20px');
        $('.top_custom_scroll').width($('#myCourse').width());
        setTimeout(function() {
            $('#myCourse tr th').eq(0).css('width', '60px');
            $('#myCourse tr td').eq(0).css('width', '60px');
            $('#hidden_myCourse tr th').eq(0).css('width', '60px');
            $('#hidden_myCourse tr td').eq(0).css('width', '60px');
        }, 500);
    }
  /*  $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
  
     var page=$('#page_type').val();
	 var url=$('#url_adress_grid_list').val();
  $.ajax({
                type: 'POST',
                dataType: 'JSON',
                url: url,
                data: {
                 //   courseids: courseids,
                    type: active,
                },
                success: function(data) {
                  
                }
            });*/

})
jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    "non-empty-string-asc": function(a, b) {
        if (a == "")
            return 1;
        if (b == "")
            return -1;
        var x = parseInt(a);
        var y = parseInt(b);
        return ((isNaN(x) || x < y) ? -1 : ((isNaN(y) || x > y) ? 1 : 0));
    },

    "non-empty-string-desc": function(a, b) {
        if (a == "")
            return 1;
        if (b == "")
            return -1;
        var x = parseInt(a);
        var y = parseInt(b);
        return ((isNaN(x) || x < y) ? 1 : ((isNaN(y) || x > y) ? -1 : 0));
    }
});
$(document).on('click', '.filter_btn', function() {
    var subject = $('#choose_subject').val();
    var teacher = $('#choose_teacher').val();
    var status = $('#choose_status').val();
    if (subject == 0) {
        subject = "";
    }
    if (teacher == 0) {
        teacher = "";
    }
    if (status == 0) {
        status = "";
    }
    table.columns([2])
        .search(subject ? '^' + subject + '$' : '', true, false)
        .draw();
    gv_table.columns([2])
        .search(subject ? '^' + subject + '$' : '', true, false)
        .draw();
    table.columns([3])
        .search(teacher, false, true, false)
        .draw();
    gv_table.columns([3])
        .search(teacher, false, true, false)
        .draw();
    table.columns([13])
        .search(status, false, true, false)
        .draw();
    gv_table.columns([4])
        .search(status, false, true, false)
        .draw();
})
$('.filter_tab').on('click', function() {
    var status = $(this).attr('data-status');
    if (status == 0) {
        status = "";
    }
    table.columns([13])
        .search(status, false, true, false)
        .draw();
    gv_table.columns([4])
        .search(status, false, true, false)
        .draw();
})
window.course_sort = function(elem){
    var col = elem.value
    if (col == 0) {
        col = "";
    }
    console.log(col);
    console.log(gv_table);
    console.log(table);
    if (col == "Default") {
        gv_table.order([0, "asc"]).draw();
        table.order([3, "asc"]).draw();
    } else if (col == "Oldest") {
        gv_table.order([5, "asc"]).draw();
        table.order([15, "asc"]).draw();
    } else if (col == "Newest") {
        gv_table.order([5, "desc"]).draw();
        table.order([15, "desc"]).draw();

    } else if (col == "Subjects") {
        gv_table.order([2, "asc"]).draw();
        table.order([2, "asc"]).draw();

    } else if (col == "Teachers") {
        gv_table.order([3, "asc"]).draw();
        table.order([4, "asc"]).draw();


    } else if (col == "Most_Registered") {
        gv_table.order([6, "desc"]).draw();
        table.order([16, "desc"]).draw();

    } else if (col == "Last_Registered") {
        gv_table.order([7, "desc"]).draw();
        table.order([14, "desc"]).draw();


    }

}


window.updateCourse = function(obj, course_id_post){
    if ($(obj).prop('checked')) {
        tt = $('.course_' + course_id_post).val();
        $('.course_' + course_id_post).val(tt + $(obj).val() + '|');
    } else {
        tt = $('.course_' + course_id_post).val();
        tt = tt.replace($(obj).val() + '|', '');
        $('.course_' + course_id_post).val(tt);
    }

}


window.hideupdateCourse = function(obj, course_id_post){
    if ($(obj).prop('checked')) {
        tt = $('.course_hide_' + course_id_post).val();
        $('.course_hide_' + course_id_post).val(tt + $(obj).val() + '|');
    } else {
        tt = $('.course_hide_' + course_id_post).val();
        tt = tt.replace($(obj).val() + '|', '');
        $('.course_hide_' + course_id_post).val(tt);
    }
}


window.save_status = function(type, course_id, obj){
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    var courseids = $('.course_' + course_id).val();

    if (courseids !== "") {

        if (type == 'delete') {
            $('html, body').animate({
                scrollTop: $(".confirmationbox").offset().top
            }, 2000);
            $('.confirmationbox').html('<div class="modal-dialog modal-lg ibR_modal ibr_border"> <div class="modal-content ibR_bg_light"><div class="modal-header"><h4 class="modal-title ibR_modal_title ibR_forecolor"><span style="">Are you sure you want to delete courses ?</span></h4></div><div class="modal-footer text-center"><button onclick="save_status(\'delete1\',\'' + course_id + '\')" class="ib_btn"><span style="">Yes</span></button><button type="button" onclick="$(\'.confirmationbox\').html(\'\')" class="ib_btn"><span style="">No</span></button></div></div></div>');
        } else if (type == 'edit') {
            if (courseids.split('|').length - 1 < 2) {
                var edit_course_id = courseids.replace('|', '');
                window.location.href = '/course/' + edit_course_id + '/edit';
            } else {
                errorMessage("Please select only 1 Course!");
            }
        } else {
            $.ajax({
                type: 'POST',
                dataType: 'JSON',
                url: '/course_status_new',
                data: {
                    courseids: courseids,
                    type: type,
                },
                success: function(data) {
                    if (data.status) {
                        location.reload();
                    } else {
                        errorMessage("Something went wrong");
                    }
                }
            });
        }
    } else {
        errorMessage("Please select at least 1 Course!");
    }
}

window.share_icon_open = function(user_id, id){
    //      var objFra = document.getElementById('myFrame');
    $('.share_display').show();
    document.getElementById("popupdarkbg").style.display = "block";
    document.getElementById("popup").style.display = "block";
    var url = '{{(url("share_icon"))}}';
    var new_url = url + '/' + user_id + '/' + id;
    var new_url1 = url + '/' + user_id.toString() + '/' + id.toString();
    document.getElementById('myFrame_icon').setAttribute("src", new_url);
    document.getElementById('popupdarkbg').onclick = function() {
        document.getElementById("popup").style.display = "none";
        document.getElementById("popupdarkbg").style.display = "none";

    }
}
window.like_wall = function(post_id){
    $.ajax({
        type: 'get',
        url: '/like_wall_post',
        data: {
            post_id: post_id,
        },
        success: function(data) {
            var status = data.msg;
            if (status == 1) {
                $("#like" + post_id).html(data.like_count);
                $("#like_icon" + post_id).toggleClass("fa fa-heart-o fa fa-heart");
            }

        }
    });
}

window.like_article = function(article_id){
    $.ajax({
        type: 'get',
        url: '/like_article',
        data: {
            article_id: article_id,
        },
        success: function(data) {
            var status = data.msg;
            if (status == 1) {
                $("#like_article_" + article_id).html(data.like_count);
                $("#like_article_icon_" + article_id).toggleClass("fa fa-heart-o fa fa-heart");
            }

        }
    });
}

window.like_topic = function(topic_id){
    $.ajax({
        type: 'get',
        url: '/like_topic',
        data: {
            topic_id: topic_id,
        },
        success: function(data) {
            var status = data.msg;
            if (status == 1) {
                $("#like_topic_" + topic_id).html(data.like_count);
                $("#like_topic_icon_" + topic_id).toggleClass("fa fa-heart-o fa fa-heart");
            }

        }
    });
}

window.like_question = function(question_id){
    $.ajax({
        type: 'get',
        url: '/like_question',
        data: {
            question_id: question_id,
        },
        success: function(data) {
            var status = data.msg;
            if (status == 1) {
                $("#like_question_" + question_id).html(data.like_count);
                $("#like_question_icon_" + question_id).toggleClass("fa fa-heart-o fa fa-heart");
            }

        }
    });
}


window.like_ticket = function(ticket_id){
    $.ajax({
        type: 'get',
        url: '/like_ticket',
        data: {
            ticket_id: ticket_id,
        },
        success: function(data) {
            var status = data.msg;
            if (status == 1) {
                $("#like_ticket_" + ticket_id).html(data.like_count);
                $("#like_ticket_icon_" + ticket_id).toggleClass("fa fa-heart-o fa fa-heart");
            }

        }
    });
}


$(document).on('click', '.edit_post_id', function() {
    var id = $(this).data('id');
    $('.edit_post_' + id).show();
    loadWYSIWUGEditor(id)
})
$(document).on('click', '.comment_post_id', function() {
    var id = $(this).data('id');
    $('.comment_post_' + id).show();
})
$(document).on('click', '.comment_article_id', function() {
    var id = $(this).data('id');
    $('.comment_article_' + id).show();
})

$(document).on('click', '.comment_topic_id', function() {
    var id = $(this).data('id');
    $('.comment_topic_' + id).show();
})

$(document).on('click', '.comment_question_id', function() {
    var id = $(this).data('id');
    $('.comment_question_' + id).show();
})


$(document).on('click', '.comment_post_btn', function() {
    var id = $(this).data('id');
    var user_id = $('.user_id_' + id).val();
    var post_id = $('.post_id_' + id).val();
    var comment = $('.comment_description_' + id).val();
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({
        type: 'POST',
        dataType: 'JSON',
        url: '/comment_wall_post',
        data: {
            comment: comment,
            user_id: user_id,
            post_id: post_id,
        },
        success: function(data) {
            if (data.status) {
                location.reload();
            } else {
                alert("Something went wrong");
            }
        }
    });
});

$(document).on('click', '.comment_article_btn', function() {
    var id = $(this).data('id');
    var user_id = $('.user_id_' + id).val();
    var article_id = $('.article_id_' + id).val();
    var comment = $('.comment_article_description_' + id).val();
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({
        type: 'POST',
        dataType: 'JSON',
        url: '/comment_wall_article',
        data: {
            comment: comment,
            user_id: user_id,
            article_id: article_id,
        },
        success: function(data) {
            if (data.status) {
                location.reload();
            } else {
                alert("Something went wrong");
            }
        }
    });
});


$(document).on('click', '.comment_topic_btn', function() {
    var id = $(this).data('id');
    var user_id = $('.user_id_' + id).val();
    var topic_id = $('.topic_id_' + id).val();
    var comment = $('.comment_topic_description_' + id).val();
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({
        type: 'POST',
        dataType: 'JSON',
        url: '/comment_wall_topic',
        data: {
            comment: comment,
            user_id: user_id,
            topic_id: topic_id,
        },
        success: function(data) {
            if (data.status) {
                location.reload();
            } else {
                alert("Something went wrong");
            }
        }
    });
});


$(document).on('click', '.comment_question_btn', function() {
    var id = $(this).data('id');
    var user_id = $('.user_id_' + id).val();
    var question_id = $('.question_id_' + id).val();
    var comment = $('.comment_question_description_' + id).val();
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({
        type: 'POST',
        dataType: 'JSON',
        url: '/comment_wall_question',
        data: {
            comment: comment,
            user_id: user_id,
            question_id: question_id,
        },
        success: function(data) {
            if (data.status) {
                location.reload();
            } else {
                alert("Something went wrong");
            }
        }
    });
});


$(document).on('click', '.edit_post_btn', function() {
    var id = $(this).data('id');
    var post_id = $('.post_id_' + id).val();
    var post_description = $('.post_description_' + id).val();
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({
        type: 'POST',
        dataType: 'JSON',
        url: '/edit_wall_post',
        data: {
            post_description: post_description,
            post_id: post_id,
        },
        success: function(data) {
            if (data.status) {
                location.reload();
            } else {
                alert("Something went wrong");
            }
        }
    });
})
const public_view = document.querySelectorAll("a[href='#public_view']");
if (public_view[0]) {
    public_view[0].addEventListener('click', function loadPublicView() {
        $.ajax({
            type: 'GET',
            url: "/public_view",
            success: function(data) {
                if (data) {
                    $('#public_view').html(data);
                }
            }
        })
    })
}
const private_view = document.querySelectorAll("a[href='#private_view']");
if (private_view[0]) {
    private_view[0].addEventListener('click', function loadPrivateView() {
        $.ajax({
            type: 'GET',
            url: "/private_view",
            success: function(data) {
                if (data) {
                    $('#private_view').html(data);
                }
            }
        })
    })
}
window.checked_box = function(){
    var course_array = [];
    if ($('.checked_checkboxes').is(':checked')) {
        $('.course_1').val('');
        $('.checked_checkboxes').prop('checked', false);
    } else {
        $('.checked_checkboxes').map(function() {
            re_value = this.value;
            final_value = re_value + '|';
            course_array.push(final_value)
        }).get();

        var course_data = course_array.join('');
        $('.course_1').val(course_data);
        $('.checked_checkboxes').prop('checked', true);
    }
}
window.hide_checked_box = function(){
    var course_array = [];
    if ($('.hide_checked_checkboxes').is(':checked')) {
        $('.course_hide_1').val('');
        $('.hide_checked_checkboxes').prop('checked', false);
    } else {
        $('.hide_checked_checkboxes').map(function() {
            re_value = this.value;
            final_value = re_value + '|';
            course_array.push(final_value)
        }).get();

        var course_data = course_array.join('');
        $('.course_hide_1').val(course_data);
        $('.hide_checked_checkboxes').prop('checked', true);
    }
}
$(".courses__content").hide();
$(".toggle-hiddencourses").on('click', function(){
    $("i", this).toggleClass("fa fa-angle-double-up fa fa-angle-double-down");
    $(".courses__content").toggle();
    $("#from_hidden_list").toggle();
});

$(".toggle-hiddentickets").on('click', function(){
    $("i", this).toggleClass("fa fa-angle-double-up fa fa-angle-double-down");
    $(".all_hidden_tickets").toggle();
    $("#from_hidden_list").toggle();
});



$(".all-statistics").hide();
$(".toggle-statistics").on('click', function(){
    $("i", this).toggleClass("fa fa-angle-double-up fa fa-angle-double-down");
    $(".all-statistics").toggle();
});

$(".panel-visitor .panel-body").hide();
$(".toogle-visitor").on('click', function(){
    $("i", this).toggleClass("fa fa-angle-double-up fa fa-angle-double-down");
    $(".panel-visitor .panel-body").toggle();
});