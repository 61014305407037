/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

/*import $ from 'jquery';
window.$ = window.jQuery = $;
*/

//import 'select2/dist/js/select2.min.js';
require('select2');
require('trumbowyg');
//require('html2canvas');
window.html2canvas = require('html2canvas');
require('jquery-resizable');
require('cropper');
$('select').select2();
/*   plugins trumbowyg */



/*   End plugins trumbowyg */

$(document).ready(function(){      
$.trumbowyg.svgPath = 'https://cdnjs.cloudflare.com/ajax/libs/Trumbowyg/2.25.1/ui/icons.svg';
   // $.trumbowyg.svgPath = '/ui/icons.svg';
   /* $('textarea').trumbowyg({
    btns: ['strong', 'em', '|', 'link'],
    autogrow: true,
    btns: [
        ['viewHTML'],
        ['undo', 'redo'], // Only supported in Blink browsers
        ['formatting'],
        ['strong', 'em', 'del'],
        ['superscript', 'subscript'],
        ['table'],
        ['link'],
        ['image'],
        ['insertAudio'],
        ['noembed'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
        ['unorderedList', 'orderedList'],
        ['horizontalRule'],
       // ['emoji'],
        ['specialChars'],
        ['removeformat'],
        ['foreColor'],
        ['backColor'],
        ['fontfamily'],
        ['fontsize'],
['lineheight'],
        ['textdirection'],
        ['fullscreen'],
				]

    });*/
   
});

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
/*
const app = new Vue({
    el: '#app',
});
*/